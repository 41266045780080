import { useMemo } from 'react';

//* Components
import Container from '@/components/global/Container';
import Text from '@/components/global/Text';
import LottieAnimation from '@/components/global/LottieAnimation';

//* Lottie Home
import trail1 from '@/lottieFiles/trails/4.json';
import trail2 from '@/lottieFiles/trails/3.json';
import trail3 from '@/lottieFiles/trails/2.json';
import trail4 from '@/lottieFiles/trails/5.json';
import trail5 from '@/lottieFiles/trails/1.json';

//* Lottie Trail
import KPI3 from '@/lottieFiles/trails/KPI3.json';
import KPI4 from '@/lottieFiles/trails/KPI4.json';
import KPI5 from '@/lottieFiles/trails/KPI5.json';
import KPI6 from '@/lottieFiles/trails/KPI6.json';
import KPI7 from '@/lottieFiles/trails/KPI7.json';

//* Style
import KeyNumbersHomeStyle from './style';

const KeyNumbersHome = ({ data, isHome = false }) => {
	const lottieWrapper = useMemo(() => {
		if (isHome) {
			return data.slice(0, 5).map((item, i) => {
				return (
					<div
						key={i}
						className={`every-lottie`}>
						<LottieAnimation
							loop
							autoplay
							animData={item.additional_info.lottie_type === 'total_length' ? trail1 : item.additional_info.lottie_type === 'number_of_stages' ? trail2 : item.additional_info.lottie_type === 'average_stage_length' ? trail3 : item.additional_info.lottie_type === 'minimum_stage_length' ? trail4 : item.additional_info.lottie_type === 'maximum_stage_length' ? trail5 : null}
						/>

						<div className={'wrapper-text'}>
							<Text className={'brand-light-blue-color font-montserrat-semibold h3 title'}>{item.subtitle}</Text>
							<Text className={'font-montserrat-regular blue-1000-color description p1'}>{item.title}</Text>
						</div>
					</div>
				);
			});
		} else {
			return data.map((item, i) => {
				return (
					<div
						key={i}
						className={`every-lottie`}>
						<LottieAnimation
							loop
							autoplay
							animData={item.additional_info.lottie_type === 'total_length' ? trail1 : item.additional_info.lottie_type === 'number_of_stages' ? trail2 : item.additional_info.lottie_type === 'average_stage_length' ? KPI3 : item.additional_info.lottie_type === 'minimum_stage_length' ? KPI4 : item.additional_info.lottie_type === 'maximum_stage_length' ? KPI5 : item.additional_info.lottie_type === 'guesthouses_supported' ? KPI6 : item.additional_info.lottie_type === 'days_spent_on_building' ? KPI7 : null}
						/>

						<div className={'wrapper-text'}>
							<Text className={'brand-light-blue-color font-montserrat-semibold h3 title'}>{item.subtitle}</Text>
							<Text className={'font-montserrat-regular blue-1000-color description p1'}>{item.title}</Text>
						</div>
					</div>
				);
			});
		}
	}, [data, isHome]);

	return (
		<KeyNumbersHomeStyle>
			<Container
				className={'change-class-name-kpi'}
				row
				full
				isSection>
				<div className='wrapper-all-lottie'>{lottieWrapper}</div>
			</Container>
		</KeyNumbersHomeStyle>
	);
};

export default KeyNumbersHome;
